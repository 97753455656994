<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <PageTitleComponent :title="`블랙리스트 ${mode_text}`" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-tabs v-model="tabModel" centered grow background-color="indigo lighten-4" slider-color="indigo darken-3">
            <v-tab key="tab1">
              블랙리스트 등록
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-tabs-items v-model="tabModel">
            <v-tab-item key="add" class="pb-8" style="background: #F3F3F3;">
              <ItemUserComponent v-model="model" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify="center" class="mt-5">
        <v-col cols="auto">
          <v-btn color="" class="mx-4" @click="backToListPage">
            <v-icon small>
              list
            </v-icon>
            <span class="pl-2">목록으로</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import PageTitleComponent from '../_elements/title/PageTitle'
import ItemUserComponent from './ItemUser'

const locations = [
  { text: '전체', value: 1 },
  { text: '서울', value: 2 },
  { text: '부산', value: 3 },
  { text: '대구', value: 4 },
  { text: '인천', value: 5 },
  { text: '광주', value: 6 },
  { text: '대전', value: 7 },
  { text: '울산', value: 8 },
  { text: '세종', value: 9 },
  { text: '경기', value: 10 },
  { text: '강원', value: 11 },
  { text: '충북', value: 12 },
  { text: '충남', value: 13 },
  { text: '전북', value: 14 },
  { text: '전남', value: 15 },
  { text: '경북', value: 16 },
  { text: '경남', value: 17 },
  { text: '제주', value: 18 }
]

export default {
  components: {
    PageTitleComponent,
    ItemUserComponent
  },
  data() {
    return {
      model: {},
      carerIdx: null,
      tabModel: 'add',
      mode: '',
      mode_text: ''
    }
  },
  async created() {
    await this.setup()
  },
  methods: {
    async setup() {
      const { id } = this.$route.params
      if (id === 'add') {
        this.mode = 'add'
        this.mode_text = '등록'
      } else {
        this.mode = 'edit'
        this.mode_text = '수정'
      }
    },
    backToListPage() {
      this.$router.push(`/blacklist`)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #eeeeee;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
.filepond--wrapper {
  ::v-deep .filepond--credits {
    display: none;
  }
}
</style>
